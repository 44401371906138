<template>
    <div id="start">

        <!-- Bottom Navigation -->
        <nav class="navbar navbar-light navbar-expand d-xl-none fixed-bottom" style="max-height:50px; font-size: 22px; background:#000; border:1px solid #000;" id="navbar-wd">
            <ul class="navbar-nav nav-justified w-100">
                <li v-if="this.$route.path == '/'" class="nav-item" id="back-to-top">
                    <a href="javascript:void()" class="nav-link active" style="color:white;">
                        <img src="/instgrm/home-active.png" alt="" style="width:24px;">
                    </a>
                </li>
                <li v-else class="nav-item">
                    <router-link to="/" class="nav-link" active-class="active" exact>
                        <img src="/instgrm/home.png" alt="" style="width:24px;">
                    </router-link>
                </li>
                <li></li>
                <li class="nav-item">
                    <router-link to="/location" class="nav-link" active-class="active">
                        <img v-if="this.$route.path == '/location'" src="/instgrm/search-active.png" alt="" style="width:23px;">
                        <img v-else src="/instgrm/search.png" alt="" style="width:23px;">
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/gallery" class="nav-link" active-class="active">
                        <img v-if="this.$route.path == '/gallery'" src="/instgrm/camera-active.png" alt="" style="width:26px;">
                        <img v-else src="/instgrm/camera.png" alt="" style="width:26px;">
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/story" class="nav-link" active-class="active">
                        <img v-if="this.$route.path == '/story'" src="/instgrm/love-active.png" alt="" style="width:24px;">
                        <img v-else src="/instgrm/love.png" alt="" style="width:24px;">
                    </router-link>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" onclick="PlaySound()" value="0" class="nav-link">
                    <img class="icon-sound" src="/instgrm/flaticon/play.png" alt="" style="width:36px;">
                    </a>
                </li>
            </ul>
        </nav>

        <!-- Loader -->
        <div id="preloader">
            <div class="preloader pulse">
            <i class="fa fa fa-heart text-danger" aria-hidden="true"></i>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="modalOpening" tabindex="-1" role="dialog" aria-labelledby="modalOpeningTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered wow bounceIn" data-wow-delay="1s" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <center style="color:#000;">
                            <h2 class="pt-4 text-dark">{{ urlParam ? urlParam : 'Hi,' }}</h2>
                            <p>
                                You're invited <br/>
                                The Wedding Celebration of 
                                <span>
                                    <p>
                                        <strong style="color:#dd666c; font-family: 'Marck Script', cursive; font-size:40px;">{{ this.coupleName }}</strong>
                                    </p>
                                </span>
                            </p>
                            <div class="pt-2 pb-4">
                                <button type="button" onclick="PlaySound()" value="1" class="btn btn-danger text-white" data-dismiss="modal">
                                    Open Invitation
                                </button>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
        </div>

        <!-- Main -->
        <section class="main">
            <div class="wrapper">

                <router-view :urlParam="this.urlParam"></router-view>

                <div class="right-col" style="margin-top:50px;">
                    <div class="profile-card">
                        <div class="profile-pic">
                            <img src="/photos/rk.jpg" alt="">
                        </div>
                        <div>
                            <p class="username">The Wedding of {{ this.coupleName }}</p>
                            <p class="sub-text">{{ this.username }}</p>
                        </div>
                        <router-link to="/gallery" class="action-btn">
                            View
                        </router-link>
                    </div>
                    <p class="suggestion-text">Suggestions for you</p>
                    <div class="profile-card">
                        <div class="profile-pic">
                            <vue-letter-avatar size="40" name="Ondangan Website" :rounded=true />
                        </div>
                        <div>
                            <p class="username">Ondangan Website</p>
                            <p class="sub-text">followed by your friend</p>
                        </div>
                        <button class="action-btn" onClick="window.open('https://instagram.com/ondangan.website', '_blank', 'noopener')">follow</button>
                    </div>
                    <div class="profile-card">
                        <div class="profile-pic">
                            <vue-letter-avatar size="40" name="Website Ondangan" :rounded=true />
                        </div>
                        <div>
                            <p class="username">Website Ondangan</p>
                            <p class="sub-text">followed by your friend</p>
                        </div>
                        <button class="action-btn" onClick="window.open('https://instagram.com/ondangan.website', '_blank', 'noopener')">follow</button>
                    </div>
                    <div class="profile-card">
                        <div class="profile-pic">
                            <vue-letter-avatar size="40" name="Ondangan Website" :rounded=true />
                        </div>
                        <div>
                            <p class="username">Ondangan Website</p>
                            <p class="sub-text">followed by your friend</p>
                        </div>
                        <button class="action-btn" onClick="window.open('https://instagram.com/ondangan.website', '_blank', 'noopener')">follow</button>
                    </div>
                    <div class="profile-card">
                        <div class="profile-pic">
                            <vue-letter-avatar size="40" name="Website Ondangan" :rounded=true />
                        </div>
                        <div>
                            <p class="username">Website Ondangan</p>
                            <p class="sub-text">followed by your friend</p>
                        </div>
                        <button class="action-btn" onClick="window.open('https://instagram.com/ondangan.website', '_blank', 'noopener')">follow</button>
                    </div>
                </div>

            </div>
        </section>

        <!-- Audio -->
        <audio id="myAudio">
            <source src="/sounds/bensound-acousticbreeze.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
        </audio>

        <button type="button" onclick="PlaySound()" value="1" class="d-none d-lg-block btn btn-circle btn-sound" title="Play Audio" style="background:#63c7bd;">
            <i class="icon-sound fa fa-pause"></i>
        </button>
        <!-- End Audio -->

        <!-- Back to Top -->
        <button type="button" class="d-none d-lg-block btn btn-danger btn-circle back-to-top" title="Back to Top">
            <i class="fa fa-arrow-up"></i>
        </button>
        <!-- Back to Top -->

    </div>
</template>

<script>
    export default {
        name: 'App',
        data() {
            return {
                urlParam: ''
            }
        },
        created() {
            let url = new URL(location.href).searchParams.get('to')
            this.urlParam = url
            
            window.addEventListener('contextmenu', function(e) {
                e.preventDefault();
            }, false);
        },
    }
</script>