<template>
	<div id="location">
		<div class="container">
			<h3 style="margin-top:5px;padding:15px;font-weight:bold;">
                <router-link to="/">
                    <i class="fa fa-arrow-left" style="margin-left:-10px;padding-right:20px;"></i>
                    {{ this.locationEvent }}
                </router-link>
            </h3>
			<div class="row text-center justify-content-center">
                <div class="col-3" style="padding-bottom:15px;">
                    <img src="/instgrm/flaticon/pin.png" width="80" style="margin:10px;">
                </div>
                <div class="col-9">
                    <div style="margin-top:40px;">
                        Lokasi Pernikahan
                    </div>
                </div>
                <iframe :src="this.mapsEmbeded" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                <a target="_blank" :href="this.maps" rel="noopener" type="button" class="btn btn-primary text-white wow fadeIn" data-wow-delay="1s" style="margin-top:25px;">
                    Get Direction
                </a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'Location'
}
</script>