<template>
	<div id="messages" style="padding-bottom: 20px;">
		<div class="container">
			<h3 style="margin-top:5px;padding:15px;font-weight:bold;">
                <router-link to="/">
                    <i class="fa fa-arrow-left" style="margin-left:-10px;padding-right:20px;"></i>
                    Message for Us
                </router-link>
            </h3>
			<div class="row" style="padding:10px 0px 0px 0px;">
				<div class="col-lg-12 col-sm-12 col-xs-12">
					<div class="nicescroll-box" style="height: 400px; overflow-y: scroll;" v-keep-scroll-position v-chat-scroll="{always: true, smooth: false}">
						
						<div class="card">
							<ul class="list-group list-group-flush">
								<li class="list-group-item" style="padding-top:15px;color:#000;" v-for="row in messages" :key="row.key">
									<vue-letter-avatar size="40" :name=row.name :rounded=true />
									<div style="padding-left:60px;margin-top:-49px;line-height:20px;">
										<b>{{ row.name }}</b>&nbsp; <br>
										<span class="badge" :class=" row.present == 1 ? 'badge-success' : 'badge-danger'">{{ row.present == 1 ? 'Hadir' : 'Berhalangan hadir' }}</span><br>										
									</div>
									<div style="padding-left:60px;padding-top:8px;line-height:20px">
										<i>"{{ row.message }}"</i>
									</div>
									<span class="pull-right">
										<small>{{ row.created_at ? format_date(row.created_at.toMillis()) : 'a few seconds ago' }}</small>
									</span>
								</li>
							</ul>
						</div>
						
					</div>
					<br>

					<div class="contact-block" style="padding-top:50px;padding-bottom:50px;">
						<form @submit.prevent="sendMessage">
							<div class="row">
								<div class="col-md-12">
									<div class="form-group wow wow-not-repeat zoomInLeft">
										<input 
											v-model="name"
											type="text" 
											class="form-control"
											style="background:#fff;" 
											id="name" 
											name="name" 
											placeholder="Your Name">
										<div class="help-block with-errors" style="color:red;" v-if="is_required_name">{{ is_required_name }}</div>
									</div>
								</div>
								<div class="col-md-12">
									<div class="form-group wow wow-not-repeat zoomInLeft">
										<select
											v-model="present"
											class="form-control" 
											style="background:#fff;"
											id="present"
											name="present"> 
											<option selected value="" style="color:gray">Ready to attend?</option>
											<option value="1">Berkenan hadir</option>
											<option value="0">Maaf tidak bisa hadir</option>
										</select>
										<div class="help-block with-errors" style="color:red;" v-if="is_required_present">{{ is_required_present }}</div>
									</div>
								</div>
								<div class="col-md-12">
									<div class="form-group wow wow-not-repeat zoomInRight"> 
										<textarea 
											name="text_msg"
											ref="text_msg"
											v-model="text_msg"
											v-on:keydown="clear"
											v-on:keyup.ctrl.enter="sendMessage"
											class="form-control"
											style="background:#fff;"
											placeholder="Write your message"
											data-role="textarea">
										</textarea>
										<div class="help-block with-errors" style="color:red;" v-if="is_required_msg">{{ is_required_msg }}</div>
									</div>
									<div class="form-group wow wow-not-repeat zoomIn">
										<vue-recaptcha ref="recaptcha"
											@verify="onVerify" sitekey="6LeZYTMUAAAAAG_rsgpvNvSSiy1DhMU_0iM_VNRP">
										</vue-recaptcha>
										<div class="help-block with-errors" style="color:red;" v-if="is_required_captcha">{{ is_required_captcha }}</div>
									</div>
									<div class="submit-button text-center wow wow-not-repeat bounceIn">
										<button class="btn btn-primary text-white" type="submit">Send Message</button>
										<div class="clearfix"></div> 
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { firestore, datetime } from '../config/Firebase'
import VueRecaptcha from 'vue-recaptcha'
import moment from 'moment'

export default {
    name: 'Contact',
	data() {
		return {
			robot: false,
			name: '',
			present: '',
			text_msg: '',
			is_required_name: '',
			is_required_present: '',
			is_required_msg: '',
			is_required_captcha: '',
			messages: [],
		}
	},
	props: {
		urlParam: String
	},
	components: { VueRecaptcha },
	methods: {
		alert() {
			this.$toast.default('Successfully Sent', {
				position: "top-right",
				dismissible: true
			})
		},
		format_date(value){
			if (value) {
				// return moment(String(value)).format('YYYY-MM-DD')
				return moment.unix(value/1000).format("DD MMM YYYY hh:mm:ss")
			}
		},
		onVerify(response) {
			if (response) this.robot = true;
		},
		sendMessage() {
			if (!this.name) {
				this.is_required_name = 'Please enter your name'
			}
			if (!this.text_msg) {
				this.is_required_msg = 'Write your message'
			}
			if (!this.present) {
				this.is_required_present = 'Please choose one'
			}
			if (!this.robot) {
				this.is_required_captcha = 'Recaptcha is required'
			}

			if (this.robot && this.name && this.present && this.text_msg) {
				// Save
				firestore.collection('ondangan-demo-messages').add({
					name: this.name,
					present: this.present,
					message: this.text_msg,
					created_at: datetime,
				})
				this.name = ''
				this.present = ''
				this.text_msg = ''
				this.alert()
				this.clear()
				grecaptcha.reset();
			}
		},
		messageListener() {
			let self = this
			firestore.collection("ondangan-demo-messages").orderBy("created_at", "asc")
			.onSnapshot(function(snapshot) {
				snapshot.docChanges().forEach(function(change) {
					if (change.type === "added") {
						self.messages.push(change.doc.data());
					}
				});
			});
		},
		clear() {
			this.is_required_name = ''
			this.is_required_present = ''
			this.is_required_msg = ''
			this.is_required_captcha = ''
		},
	},
	mounted() {
		this.name = this.urlParam
		this.messageListener()
	},
}
</script>